
import Vue from "vue";

export default Vue.extend({
  name: "DatePicker",

  props: {
    value: {
      required: false,
      type: String
    },
    label: {
      required: false,
      default: "",
      type: String
    },
    min: {
      required: false,
      default: new Date("1970-01-01").toISOString(),
      type: String
    },
    max: {
      required: false,
      default: new Date("2100-01-01").toISOString(),
      type: String
    },
    hideDetails: {
      required: false,
      default: false,
      type: Boolean
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean
    },
    outlined: {
      required: false,
      default: false,
      type: Boolean
    },
    dense: {
      required: false,
      default: false,
      type: Boolean
    },
    errorMessages: {
      required: false,
      default: () => [],
      type: Array
    },
    rules: {
      required: false,
      default: () => [],
      type: Array
    }
  },

  data: () => ({
    date: "" as number | string | Date,
    menu: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.date = this.value as any;
        } else {
          this.dates = "";
        }
      }
    }
  },

  methods: {
    changeData(): void {
      this.$emit("input", this.date);
      this.menu = false;
    },
    close(): void {
      this.date = "";
      this.changeData();
      this.menu = false;
    },
    toggle() {
      this.menu = !this.menu;
    },
    clearDate(): void {
      this.date = "";
      this.changeData();
    },
    getFormattedTime(date: Date) {
      const newDate = this.$moment(date);

      if (newDate.isValid()) {
        return newDate.format("DD.MM.YYYY");
      }
    }
  }
});
