import Vue from "vue";
export default Vue.extend({
  methods: {
    async search(search_text: string, key: string): Promise<any> {
      try {
        return await (this.$API as any)[key]().customLiveSearch({
          search_text,
          service_types: this.model.order.service_type_id
        });
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async searchClient(value: string, key: string): Promise<any> {
      try {
        return {
          items: await (this.$API as any)[key]().liveSearchClient(value)
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    setClient(client: any): void {
      this.model.order.client_id = client.id;
      this.model.order.client = client;
      this.isSelectBeneficiary = !!client?.id;

      if (this.isCreate) {
        if (client.name) {
          this.model.order.author_full_name = client.name;
        }

        if (client.phone) {
          this.model.order.author_phone = client.phone;
        }

        if (client.email) {
          this.model.order.author_email = client.email;
        }
      }

      this.$forceUpdate();
    },
    clearClient(): void {
      delete this.model.order.client_id;
      delete this.model.order.client;
      this.isSelectBeneficiary;
    },
    setPowerOfAttorney(event: any) {
      this.powerOfAttorney = { ...event };
    },
    setBeneficiary(event: any) {
      this.setClient(event);
    }
  }
});
