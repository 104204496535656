
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "BankAccount",

  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    banks: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessages: {
      required: false,
      default: () => ({})
    },
    clientType: {
      required: false,
      type: String
    }
  },

  data: () => ({
    rules,
    model: [{}] as Array<any>
  }),

  computed: {
    isJuridical() {
      return this.clientType === "juridical";
    }
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    }
  },

  methods: {
    addRow(): void {
      this.model.push({
        account: "",
        client_id: this.clientId
      });

      this.changeData();
    },
    deleteRow(index: number): void {
      this.model.splice(index, 1);
      this.changeData();
    },
    changeData() {
      this.$emit("input", this.model);
    }
  }
});
