
import Vue from "vue";
import CreateClient from "@/modules/clients/views/create.vue";

export default Vue.extend({
  name: "CreateBeneficiary",

  components: {
    CreateClient
  },

  props: {
    value: {
      required: false,
      type: Boolean,
      default: false
    },
    serviceType: {
      required: false,
      type: Number
    }
  },

  data: () => ({
    dialog: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      async handler() {
        this.dialog = this.value;
      }
    }
  },

  methods: {
    closeDialog(): void {
      this.dialog = false;
      this.$emit("input", this.dialog);
    },
    add(user: any) {
      this.$emit("add", user);
      this.closeDialog();
    }
  }
});
