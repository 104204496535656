
import Vue from "vue";
import FileUploader from "@/components/form-components/FileUploader.vue";
import rules from "@/services/helpers/validation-rules";
import MultiplePhonePicker from "@/components/form-components/MultiplePhonePicker.vue";

export default Vue.extend({
  name: "CreateUser",

  props: {
    isDialog: {
      default: false,
      type: Boolean,
      required: false
    },
    isEmployee: {
      default: false,
      type: Boolean,
      required: false
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  components: {
    MultiplePhonePicker,
    FileUploader
  },

  data: () => ({
    rules,
    model: {
      name: "" as string,
      password: "" as string,
      email: "" as string,
      phones: [null],
      password_confirmation: "" as string,
      position_id: null as any,
      roles: [] as Array<any>
    } as any,
    items: [] as any,
    errorMessages: {} as any,
    showPassword: {
      password: false as boolean,
      confirmPassword: false as boolean
    } as any
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.isEmployee) {
          this.model.phones = this.value.phones;
          this.model.email = this.value.email;
        }
        if (
          this.value &&
          this.value.contacts &&
          this.value.contacts.length > 0
        ) {
          this.model.phones = this.value.contacts
            .filter((item: any) => item.type === "phone")
            .map((item: any) => item.value);

          if (this.value.contacts.some((item: any) => item.type === "email")) {
            const [email] = this.value.contacts
              .filter((item: any) => item.type === "email")
              .map((item: any) => item.value);
            this.model.email = email;
          }
        }
        if (this.value[`name_${this.currentLanguage}`]) {
          this.model.name = this.value[`name_${this.currentLanguage}`];
        }
      }
    }
  },

  computed: {
    currentLanguage() {
      return this.$store.getters["localization/getCurrent"];
    },
    required() {
      return (
        this.model.roles.length > 0 || this.$t("global_validation.required")
      );
    },
    isSamePassword() {
      return (
        this.model.password === this.model.password_confirmation ||
        this.$t("users.form.validation.not_match")
      );
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    async getList(): Promise<void> {
      try {
        this.items = await this.$API.roles().getList();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };

          if (this.isDialog) {
            const user = await this.$API.users().createBeneficiary(model);
            this.$emit("input", { value: user.id, text: user.name });
            this.$emit("close");
          } else if (this.isEmployee) {
            const [position] = model.roles;
            model.position_id = position;
            const user = await this.$API.users().create(model);
            this.$emit("input", { value: user.id, text: user.name });
            this.$emit("close");
          } else {
            const [position] = model.roles;
            model.position_id = position;

            await this.$API.users().create(model);
            await this.$router.push("/users");
          }

          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    generateRandomPassword() {
      this.showPassword.password = true;
      this.model.password = Array(10)
        .fill(
          "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz+!@#$%&"
        )
        .map((x: any) => {
          return x[Math.floor(Math.random() * x.length)];
        })
        .join("");
    },
    changeView(key: string): void {
      this.showPassword[key] = !this.showPassword[key];
    },
    async closeOrGoForward(): Promise<void> {
      if (this.isDialog || this.isEmployee) {
        await this.$emit("close");
      } else {
        await this.$router.push("/users");
      }
    }
  }
});
