
import Vue from "vue";
import PhonePicker from "@/components/form-components/PhonePicker.vue";

export default Vue.extend({
  name: "MultiplePhonePicker",

  components: { PhonePicker },

  props: {
    value: {
      required: false,
      type: Array,
      default: () => []
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    dense: {
      required: false,
      type: Boolean,
      default: false
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessages: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    values: [] as Array<string>
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (Array.isArray(this.values)) {
          for (const item of this.value) {
            if (!this.values.includes(item as any)) {
              this.values.push(item as any);
            }
          }
        }
      }
    }
  },

  methods: {
    changeData(): void {
      this.$emit(
        "input",
        this.values.filter(item => item)
      );
    },
    addNumber() {
      this.values.push("");
    },
    removeItem(index: number) {
      this.values.splice(index, 1);
      this.changeData();
    }
  }
});
