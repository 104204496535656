
import Vue from "vue";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import BankAccount from "@/components/form-components/BankAccount.vue";
import ContactsPicker from "@/components/form-components/ContactsPicker.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import { mask } from "vue-the-mask";
import CreateUser from "@/modules/users/views/create.vue";
//@ts-ignore
import { VDatetimeField } from "v-datetime-field";

export default Vue.extend({
  name: "CreateClient",

  components: {
    CreateUser,
    BankAccount,
    ContactsPicker,
    LiveSearch,
    VDatetimeField,
  },

  directives: { mask },

  props: {
    isDialog: {
      type: Boolean,
      default: false,
      required: false,
    },
    serviceType: {
      required: false,
      type: Number,
    },
  },

  data: () => ({
    rules,
    model: {
      client_bank_accounts: [] as Array<any>,
      contacts: [] as Array<any>,
      address_ro: "Republica Moldova, ",
      address_en: "Republic of Moldova, ",
      address_ru: "Республика Молдова, ",
      user_id: null as any,
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    selects: {
      types: [] as Array<SelectComponentInterface>,
      banks: [] as Array<SelectComponentInterface>,
      users: [] as Array<SelectComponentInterface>,
      organizationForms: [] as Array<SelectComponentInterface>,
    },
    showCreateUser: false as boolean,
    loading: false as boolean,
    user: null as any,
  }),

  computed: {
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    isJuridical() {
      return this.model.type === "juridical";
    },
    disabledTypeSelect() {
      return this.isDialog && this.serviceType === 2;
    },
    adressLabelRo() {
      return `${this.$t("clients.form.fields.address_ro_jur")}*`;
    },
    adressLabelEn() {
      return `${this.$t("clients.form.fields.address_en_jur")}*`;
    },
    adressLabelRu() {
      return `${this.$t("clients.form.fields.address_ru_jur")}*`;
    },
  },

  async mounted() {
    if (this.isDialog && this.serviceType === 2) {
      this.model.type = "juridical";
    }
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const item = await this.$API.banks().getList();
        const types = await this.$API.clients().getClientTypes();
        const organizationForms = await this.$API.organizationForms().getList();

        this.selects.banks = item;
        this.selects.types = types;
        this.selects.organizationForms = organizationForms;
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          const model = { ...this.model };

          if (model.type === "juridical") {
            delete model.identity_card;
            delete model.identity_card_issued;
            delete model.identity_card_date;
          } else {
            delete model.administrator_name;
            delete model.vat_code;
            delete model.certificate_number;
          }

          const client = await this.$API.clients().create(model);
          this.$emit("add", client);

          if (!this.isDialog) {
            await this.$router.push("/clients");
          }

          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggleCreateUser(): void {
      this.showCreateUser = !this.showCreateUser;
    },
    async setUser(user: any): Promise<void> {
      try {
        this.model.user_id = user.value;
        this.user = user;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async searchUser(searchValue: string): Promise<any> {
      try {
        return {
          items: await this.$API.users().liveSearchBeneficiary({ searchValue }),
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async close() {
      if (this.isDialog) {
        this.$emit("close");
      } else {
        await this.$router.push("/clients");
      }
    },
  },
});
