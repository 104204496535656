
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import store from "@/store";
import API from "@/api/API";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import Client from "@/modules/orders/mixins/client";
import Experts from "@/modules/orders/components/order_types/EXP/Experts.vue";
import Services from "@/modules/orders/components/Services.vue";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "Create",

  components: {
    FileUploader,
    Services,
    Experts,
    ClientData,
    DatePicker
  },

  mixins: [Client],

  props: {
    language: {
      type: String,
      default: "ru",
      required: true
    },
    serviceId: {
      required: true,
      type: Number
    },
    allocations: {
      required: true,
      type: Object
    }
  },

  directives: { mask },

  data: () => ({
    rules,
    selectedClient: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      experts: [{ percent: 100 }] as Array<any>,
      order: {
        source: "operator",
        client: { type: "" } as any,
        power_of_attorney: "beneficiary" as string,
        use_last_power_of_attorney: true as boolean
      } as any,
      details: {
        guaranty_required: true,
        services: [] as Array<any>,
        document_created_at: new Date().toISOString().substr(0, 10)
      } as any
    } as any,
    selects: {
      expertiseTypes: [] as Array<SelectComponentInterface>,
      experts: [] as Array<SelectComponentInterface>
    } as any,
    errorMessages: {} as { [value: string]: Array<string> },
    lang: store.getters["localization/getCurrent"],
    client: { type: "juridical" as string } as any,
    isLoading: false as boolean,
    loading: false as boolean,
    documents: {
      confirm_represent_power: {
        file: {} as any
      } as any
    } as any,
    selectedTab: 0 as number,
    powerOfAttorney: null as any,
    isSelectBeneficiary: false as boolean,
    isCreate: true as boolean,
    totalSum: 0 as number,
    maxDocumentCounter: 20 as number
  }),

  watch: {
    language: {
      immediate: true,
      handler() {
        this.model.order.language = this.language;
      }
    }
  },

  computed: {
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    isValidPercent() {
      return this.totalPercent !== 100;
    },
    totalPercent() {
      return this.model.experts.reduce(
        (total: number, { percent }: { percent: number }) => {
          if (Number(percent)) {
            return total + Number(percent);
          }
          return total;
        },
        0
      );
    }
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    deleteRow(index: number): void {
      this.model.documents.splice(index, 1);
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    addRow(): void {
      this.model.documents.push({ file_type: "conclusion" });
    },
    checkMainExpert(expertId: number) {
      if (this.model.experts.length === 1) {
        this.model.order.handler_id = expertId;
      }
      this.$forceUpdate();
    },
    async reload(): Promise<void> {
      await this.$forceUpdate();
    },
    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.language
        };
        this.selects.expertiseTypes = await API.expertiseTypes().getList(
          {},
          headers
        );

        this.$forceUpdate();
        this.$nextTick(() => {
          this.fullLoaded = true;
        });
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = { ...this.model };
          model.order.service_type_id = this.serviceId;

          const { branch_id, subdivision_id } = this.allocations;
          model.order.branch_id = branch_id;
          model.order.subdivision_id = subdivision_id;

          model.experts = model.experts.filter(
            (item: any) => item.user_id !== model.order.handler_id
          );
          await this.$API.orders().create(model);
          form.removeGuard();
          await this.$router.push("/orders");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        form.removeGuard();
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    setGuaranty(event: boolean): void {
      this.model.details.guaranty_required = event;
    }
  }
});
