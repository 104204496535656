
import Vue from "vue";

export default Vue.extend({
  name: "Comments",

  props: {
    field: {
      type: String,
      required: true,
      default: ""
    },
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    right: {
      type: String,
      required: false,
      default: "0"
    },
    top: {
      type: String,
      required: false,
      default: "5px"
    }
  },

  data: () => ({
    model: {} as any,
    dialog: false as boolean
  }),

  computed: {
    currentRole(): any {
      // @ts-ignore
      return this.$store.getters["user/currentRole"]?.alias;
    },
    disabled() {
      return ["bookkeeper", "bookkeeper_chief", "subdivision_head"].some(
        item => item === this.currentRole
      );
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        // @ts-ignore
        this.model = this.value;

        // @ts-ignore
        this.model.key = this.field;
      }
    }
  },

  methods: {
    updateComment() {
      this.$emit("update", this.model);
    },
    openCommentDialog() {
      this.toggleComments();
      setTimeout(() => {
        (this.$refs.comments as any).focus();
      }, 300);
    },
    toggleComments(): void {
      this.dialog = !this.dialog;
    }
  }
});
